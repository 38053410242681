<template v-loading="isLoading" id="app">
  <el-menu
    default-active="1"
    class="el-menu-vertical-calendar"
    :collapse="false"
    v-if="!isLoginView"
  >
    <el-menu-item index="1"
                  @click="$router.push({path: '/'})">
      <el-icon>
        <icon-menu/>
      </el-icon>
      <template #title>Calendrier</template>
    </el-menu-item>
    <el-menu-item index="2" v-if="isAdmin"
                  @click="$router.push({path: 'admin'})">
      <el-icon>
        <icon-menu/>
      </el-icon>
      <template #title>Admin</template>
    </el-menu-item>
    <el-menu-item index="3"
                  @click="logout">
      <el-icon>
        <icon-menu/>
      </el-icon>
      <template #title>Déconnexion</template>
    </el-menu-item>
  </el-menu>
  <router-view v-if="!isLoading"/>
</template>

<script>
import store from './store';

export default {
  data() {
    return {
      isLoginView: false,
      isLoading: true,
      isAdmin: false,
    };
  },
  async created() {
    this.checkAuthentication();
  },
  async updated() {
    this.checkAuthentication();
  },
  methods: {
    // Vérification de l'état d'authentification lors du chargement du composant
    async checkAuthentication() {
      const user = store.getters.getUser;
      this.isAdmin = user && user.role === 'admin';
      this.isLoading = false;
      if (!user || !user.token) {
        this.$router.push({ name: 'login' });
      }
    },
    logout() {
      store.commit('setUser', null);
      this.$router.push({ name: 'login' });
    },
  },
  watch: {
    $route() {
      this.isLoginView = this.$route.path === '/login';
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  display: flex;
  width: 100%;
  min-height: 100vh;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.el-menu-vertical-calendar {
  width: 200px;
  padding-top: 100px;
}

body {
  margin: 0;
}

#app {
  background-color: #f8f6ff;
}

.home {
  background-color: #f8f6ff;
}
</style>
