import { createStore } from 'vuex';

export default createStore({
  state: {
    user: {
      uid: '',
      name: '',
      firstName: '',
      lastName: '',
      email: '',
      role: '',
      token: '',
    },
    me: {
    },
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getMe(state) {
      return state.me;
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setMe(state, me) {
      state.me = me;
    },
  },
  actions: {
  },
  modules: {
  },
});
