import { createApp } from 'vue';
import VCalendar from 'v-calendar';
import ElementPlus from 'element-plus';
import App from './App.vue';
import router from './router';
import store from './store';
import 'v-calendar/style.css';
import 'element-plus/dist/index.css';

// Use plugin with optional defaults
console.warn = () => {};

createApp(App)
  .use(store)
  .use(router)
  .use(VCalendar, {})
  .use(ElementPlus)
  .mount('#app');
