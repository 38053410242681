<template>
  <div class="admin">
    <h1 class="admin-title">Admin</h1>
    <div class="table-container">
      <el-table
        :data="pendingEvents" style="width: 100%; height: auto" class="calendar"
        empty-text="Aucune demande en attente"
        >
        <el-table-column fixed prop="employeeName" sortable label="Employé" width="250" />
        <el-table-column fixed prop="eventName" sortable label="Demande" width="250" />
        <el-table-column fixed prop="dates" sortable label="Date" width="250" />
        <el-table-column fixed prop="isRecurrent" sortable label="Evenement Récurrent" width="250"/>
        <el-table-column fixed="right" label="" width="200">
          <template #default="scope">
              <el-button link type="primary" size="small"
              @click="approveEvent(scope.$index, scope.row)"
                >Accepter</el-button
              >
              <el-button link type="danger" size="small"
                @click="rejectEvent(scope.$index, scope.row)"
              >Refuser</el-button>
            </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="add-employee">
      <el-button type="primary" size="small" @click="handleAddEmployeeClick">
        Ajouter un employé
      </el-button>
    </div>
    <div class="table-container">
      <el-table
        :data="users"
        style="width: 100%"
        class="calendar mb40"
        empty-text="Aucun employé référencé"
      >
        <el-table-column fixed prop="last_name" label="Nom" width="250" />
        <el-table-column fixed prop="first_name" label="Prénom" width="250" />
        <el-table-column fixed prop="email" label="Email" width="250" />
        <el-table-column fixed prop="role" label="Rôle" width="250" />
        <el-table-column fixed="right" label="" width="200">
          <template #default="scope">
            <el-button link type="primary" size="small" @click="updateUser(scope.$index, scope.row)"
              >Modifier</el-button
            >
            <el-button link type="danger" size="small" @click="deleteUser(scope.$index, scope.row)"
            >Supprimer</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="Ajouter un employé"
      v-model="dialogVisible"
      width="600px"
    >
      <el-form :model="form" ref="form">
        <el-form-item label="Nom" :label-width="formLabelWidth">
          <el-input v-model="form.lastName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Prénom" :label-width="formLabelWidth">
          <el-input v-model="form.firstName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Email" :label-width="formLabelWidth">
          <el-input v-model="form.email" :disabled="!isCreatingUser" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Mot de passe" :label-width="formLabelWidth">
          <el-input v-model="form.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Rôle" :label-width="formLabelWidth">
          <el-select v-model="form.role" placeholder="Sélectionner un rôle">
            <el-option label="Employé" value="employee"></el-option>
            <el-option label="Admin" value="admin"></el-option>
          </el-select>
        </el-form-item>
        <div class="dialog-footer">
          <el-button type="primary" @click="createUser" v-if="isCreatingUser">Ajouter</el-button>
          <el-button type="primary" @click="updateUserInDB" v-if="!isCreatingUser">
            Modifier</el-button>
          <el-button @click="resetForm()">Annuler</el-button>
          </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
// import { createUserWithEmailAndPassword } from 'firebase/auth';
import { ElMessageBox } from 'element-plus';
// import { auth } from '../firebase';

import {
  createUser,
  getUsers,
  deleteUser,
  updateUser,
} from '../api/user';

import {
  getPendingEvents,
  approveEvent,
  rejectEvent,
} from '../api/event';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Admin',
  data() {
    return {
      users: [],
      isCreatingUser: true,
      formLabelWidth: '120px',
      pendingEvents: [],
      form: {
        name: '',
        firstName: '',
        email: '',
        password: '',
        role: '',
      },
      dialogVisible: false,
    };
  },
  methods: {
    approveEvent(index, row) {
      approveEvent(row);
      this.pendingEvents.splice(index, 1);
    },
    rejectEvent(index, row) {
      rejectEvent(row);
      this.pendingEvents.splice(index, 1);
    },
    handleAddEmployeeClick() {
      this.dialogVisible = true;
      this.resetForm();
    },
    resetForm() {
      this.form = {
        lastName: '',
        firstName: '',
        email: '',
        password: '',
        role: '',
      };
    },
    async createUser() {
      const {
        lastName, firstName, email, password, role,
      } = this.form;
      const user = {
        lastName,
        firstName,
        email,
        password,
        role,
      };
      await createUser(user);
      this.resetForm();
      this.dialogVisible = false;
      await this.getUsers();
    },
    async getUsers() {
      try {
        const response = await getUsers();
        this.users = response.users;
        // Mettre les profiles par ordre alphabétique des noms
        this.users.sort((a, b) => {
          if (a.last_name < b.last_name) {
            return -1;
          }
          if (a.last_name > b.last_name) {
            return 1;
          }
          return 0;
        });
      } catch (error) {
        console.error(error);
        this.users = [];
      }
    },
    async deleteUser(index, row) {
      ElMessageBox.confirm('Êtes-vous sûr de vouloir supprimer cet utilisateur ?')
        .then(async (done) => {
          try {
            await deleteUser(row.email);
            await this.getUsers();
          } catch (error) {
            console.error(error);
          }
          done();
        })
        .catch(() => {
        // catch error
        });
    },
    updateUser(index, row) {
      this.isCreatingUser = false;
      this.dialogVisible = true;
      this.form = {
        lastName: row.last_name,
        firstName: row.first_name,
        email: row.email,
        password: row.password,
        role: row.role,
      };
    },
    async updateUserInDB() {
      const {
        lastName, firstName, email, password, role,
      } = this.form;
      const user = {
        lastName,
        firstName,
        email,
        password,
        role,
      };
      try {
        await updateUser(user);
        this.resetForm();
        this.dialogVisible = false;
        await this.getUsers();
      } catch (error) {
        console.error(error);
      }
    },
  },
  async mounted() {
    await this.getUsers();
    const { events } = (await getPendingEvents());
    const eventsWithUsers = [];
    // associate pending events to users
    events.forEach((event) => {
      const user = this.users.find((u) => u.id === event.user_id);
      if (user) {
        // eslint-disable-next-line no-param-reassign
        event.employeeName = `${user.first_name} ${user.last_name}`;
        // eslint-disable-next-line no-param-reassign
        event.email = user.email;
        // eslint-disable-next-line no-param-reassign
        event.date = new Date(event.start);
        // eslint-disable-next-line no-param-reassign
        event.date = event.date.toLocaleDateString('fr-FR');
        eventsWithUsers.push(event);
      }
    });
    // regroup events by event_id
    const eventsRegrouped = [];
    eventsWithUsers.forEach((event) => {
      const eventRegrouped = eventsRegrouped.find((e) => e.event_id === event.event_id);
      if (eventRegrouped) {
        eventRegrouped.dates.push(event.date);
      } else {
        eventsRegrouped.push({
          event_id: event.event_id,
          dates: [event.date],
          employeeName: event.employeeName,
          eventName: event.event_name,
          email: event.email,
          isRecurrent: event.event_type_id === 1 ? 'Oui' : 'Non',
        });
      }
    });
    this.pendingEvents = eventsRegrouped;
    // reformat dates
    this.pendingEvents = this.pendingEvents.map((event) => {
      const dates = `du ${event.dates[0]} au ${event.dates[event.dates.length - 1]}`;
      return {
        ...event,
        dates,
      };
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.admin {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}
.admin-title{
  margin-top: 100px;
}

.add-employee{
  margin-top: 50px;
  margin-bottom: 50px;
}

.dialog-footer{
  display: flex;
  justify-content: center;
}
.mb40{
  margin-bottom: 40px;
}
</style>
