import axios from 'axios';

const instance = axios.create({
  timeout: 5000,
  baseURL: 'https://planning.thesorimed.org/backend',
  // baseURL: 'http://localhost:3333',
  headers: {
    'Content-Type': 'application/json',
  },
});

export default instance;
