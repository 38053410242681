import axios from './axios';
import store from '../store';

const getToken = () => {
  const accessToken = store.getters.getUser.token || '';
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

export const getUsers = async () => {
  try {
    const config = getToken();
    const response = await axios.get('/users', config);
    return response.data;
  } catch (error) {
    console.error(error);
    return {
      error,
    };
  }
};

export const createUser = async (user) => {
  try {
    const config = getToken();
    const response = await axios.post('/user', user, config);
    return response.data;
  } catch (error) {
    console.error(error);
    return {
      error,
    };
  }
};

export const deleteUser = async (email) => {
  try {
    const config = getToken();
    const response = await axios.delete(`/user/${email}`, config);
    return response.data;
  } catch (error) {
    console.error(error);
    return {
      error,
    };
  }
};

export const updateUser = async (user) => {
  try {
    const config = getToken();
    const response = await axios.put('/user', user, config);
    return response.data;
  } catch (error) {
    console.error(error);
    return {
      error,
    };
  }
};
