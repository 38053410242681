import axios from './axios';
import store from '../store';

const getToken = () => {
  const accessToken = store.getters.getUser.token || '';
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

export const getEvents = async () => {
  try {
    const config = getToken();
    const response = await axios.get('/events', config);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const createEvent = async (event) => {
  try {
    const config = getToken();
    const response = await axios.post('/event', event, config);
    return response.data;
  } catch (error) {
    console.error(error);
    return {
      error,
    };
  }
};

export const deleteEvent = async (event) => {
  try {
    const config = getToken();
    const response = await axios.post('/event/delete', event, config);
    console.log(event);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    return {
      error,
    };
  }
};

export const updateEvent = async (event) => {
  try {
    const config = getToken();
    const response = await axios.put('/event', event, config);
    return response.data;
  } catch (error) {
    console.error(error);
    return {
      error,
    };
  }
};

export const getPendingEvents = async () => {
  try {
    const config = getToken();
    const response = await axios.get('/event/pending', config);
    return response.data;
  } catch (error) {
    console.error(error);
    return {
      error,
    };
  }
};

export const approveEvent = async (event) => {
  try {
    console.log('event');
    console.log(event);
    const config = getToken();
    const response = await axios.post('/event/approve', event, config);
    console.log(event);
    return response.data;
  } catch (error) {
    console.error(error);
    return {
      error,
    };
  }
};

export const rejectEvent = async (event) => {
  try {
    const config = getToken();
    const response = await axios.post('/event/reject', event, config);
    return response.data;
  } catch (error) {
    console.error(error);
    return {
      error,
    };
  }
};
