<template>
  <div class="login">
    <h1 class="login-title">
      Bienvenue sur l'application de gestion des absences
    </h1>
    <el-form
      label-position="top"
      label-width="100px"
      style="max-width: 460px"
    >
      <el-form-item label="Mail">
        <el-input v-model="email" />
      </el-form-item>
      <el-form-item label="Password">
        <el-input v-model="password"  type="password"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="signInWithEmailAndPassword">
          Connexion
        </el-button>
        <el-button
          @click="alertMDP">Mot de passe oublié
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import axios from '../api/axios';

export default {
  name: 'LoginView',
  data() {
    return {
      email: '',
      password: '',
    };
  },
  components: {
  },
  methods: {
    signInWithEmailAndPassword() {
      axios.post('/login', { email: this.email, password: this.password })
        .then((userCredential) => {
          // Signed in
          this.$store.commit('setUser', userCredential.data.user);
          this.$router.push({ name: 'home' });
        })
        .catch((error) => {
          console.log(error);
          alert('Mail ou mot de passe incorrect');
        });
    },
    alertMDP() {
      // eslint-disable-next-line no-alert
      alert('Veuillez contacter votre administrateur');
    },
  },
};
</script>

<style scoped>
.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
</style>
