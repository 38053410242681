/* eslint-disable import/no-extraneous-dependencies */
import joursFeriesGouv from '@socialgouv/jours-feries';

export const isJourFerie = (inputDate) => {
  const date = new Date(inputDate);
  const year = new Date(date).getFullYear();
  const joursFeries = Object.values(joursFeriesGouv(year));
  const result = joursFeries.find((jourFerie) => {
    const jourFerieDate = new Date(jourFerie);
    // eslint-disable-next-line max-len
    return jourFerieDate.getDate() === date.getDate() && jourFerieDate.getMonth() === date.getMonth();
  });
  return !!result;
};

export const isWeekend = (date) => {
  const day = date.getDay();
  return day === 0 || day === 6;
};
