<template>
  <div class="home">
    <Calendar/>
  </div>
</template>

<script>
// @ is an alias to /src
import Calendar from '@/components/Calendar.vue';

export default {
  name: 'HomeView',
  components: {
    Calendar,
  },
};
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: 100px;
}
</style>
