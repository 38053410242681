<!-- eslint-disable max-len -->
<template>
  <div class="calendar">
    <h1 class="calendar-title">Bonjour {{ me.first_name }}, Voici le planning des absences</h1>
    <div class="calendar-buttons today-button">
      <el-button type="plain" link @click="setToday">Aujourd'hui</el-button>
    </div>
    <!-- buttons in line : last week, next week -->
    <div class="calendar-buttons">
      <el-button type="plain" link @click="setPreviousWeek">← Semaine précédente</el-button>
      <el-button type="plain" link @click="setNextWeek">Semaine suivante →</el-button>
    </div>

    <!-- buttons in line : last month, next month -->
    <div class="calendar-buttons last-buttons">
      <el-button type="plain" link @click="setPreviousMonth">← Mois précédent</el-button>
      <el-button type="plain" link @click="setNextMonth">Mois suivant →</el-button>
    </div>

    <div class="month-year">
      <h2>{{ getMonth() }} {{ getYear() }}</h2>
    </div>
    <div>

      <el-table
        ref="calendar"
        :data="formattedEmployeesAbsentees" style="width: 100%" class="calendar disable-hover-row"
        :cell-class-name="cellColor"
        @cell-click="handleCellClick"
        empty-text="Chargement en cours ..."
      >
        <el-table-column fixed prop="employeLastName" label="Nom" sortable min-width="180" width="180" show-overflow-tooltip="true"></el-table-column>
        <el-table-column fixed prop="employeFirstName" label="Prénom" min-width="120" width="120" show-overflow-tooltip="true"></el-table-column>
        <el-table-column v-for="day in listOfNext30Days" min-width="80"
                         :key="'date_' + day.getTime()"
                         :prop="`date_${day.getDate()}/${day.getMonth() + 1}/${day.getFullYear()}`"
                         :type="day.getDay() === 0 || day.getDay() === 6 || isJourFerie(day)"
                         :label="daysOfWeek[day.getDay()] +' ' + day.getDate().toString()">
          <template v-slot="{ row }">
            {{ setEventName(row, day) }}
          </template>
        </el-table-column>
      </el-table>
    </div>

    <h3 class="absentees-list mt100">Types d'absence</h3>
    <div class="absentees-list">
      <!-- List in two lines the typeOfAbsentees -->
      <div>
      </div>
      <div class="absentee-type" v-for="absenteeType in typeOfAbsentees" :key="absenteeType">
        <div class="absentee-type-name" :class="getAbsenteeColor(absenteeType)">{{ absenteeType }}</div>
      </div>
    </div>
    <p class="absentees-list">* Justificatif à fournir impérativement à la Direction.
    </p>

    <el-dialog
      v-model="isDialogOpen"
      title="Absences"
      width="60%"
      destroy-on-close
      center
    >
      <div>
        <h2>{{ formatAbsentee.employeName }}</h2>
        <div>
          <el-checkbox v-if="isCreateAbsenteeOpen" v-model="formatAbsentee.isRecurrent" label="Évènement récurrent" size="large"/>
          <el-checkbox v-if="isModifyAbsenteeOpen && formatAbsentee.isRecurrent" v-model="formatAbsentee.isRecurrent" label="Évènement récurrent" size="large" disabled/>
          <div class="mb-2 flex items-center text-sm">
            <el-radio-group v-model="onlyOneEventToDelete" class="ml-4" v-if="canBeModified">
              <el-radio v-if="isModifyAbsenteeOpen && formatAbsentee.isRecurrent" :label="true">Supprimer uniquement ce jour</el-radio>
              <el-radio v-if="isModifyAbsenteeOpen && formatAbsentee.isRecurrent" :label="false">Supprimer toutes les dates de cette récurrence</el-radio>
            </el-radio-group>
            <el-radio-group v-model="onlyOneEventToDelete" class="ml-4" v-if="!canBeModified">
              <el-radio v-if="isModifyAbsenteeOpen && formatAbsentee.isRecurrent" :label="false">Supprimer toutes les dates de cette récurrence</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div >
          <el-select v-if="formatAbsentee.isRecurrent && isCreateAbsenteeOpen" v-model="formatAbsentee.recurrentTempo" class="m-2" placeholder="Fréquence" size="large">
            <el-option
              v-for="item in tempos"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </div>
        <div class="flex-justify">
          <el-select v-if="!isModifyAbsenteeOpen || !formatAbsentee.isRecurrent"
                     v-model="formatAbsentee.event_name" class="m-2" placeholder="Type d'absence" size="large">
            <el-option
              v-for="item in typeOfAbsentees"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </div>
        <div class="date-picker-container">
          <VDatePicker v-model.range="range" mode="date" v-if="!formatAbsentee.isRecurrent" expanded/>
          <VDatePicker v-model="range.start" mode="date" v-if="formatAbsentee.isRecurrent" expanded/>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isDialogOpen = false">Fermer</el-button>
          <el-button type="primary" @click="createEvent" v-if="isCreateAbsenteeOpen">
            Valider
          </el-button>
          <el-button type="warning" @click="updateEvent" v-if="isModifyAbsenteeOpen && canBeModified && (!isModifyAbsenteeOpen || !formatAbsentee.isRecurrent)">Modifier</el-button>
          <el-button type="danger" @click="deleteEvent" v-if="isModifyAbsenteeOpen">Supprimer</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getUsers } from '../api/user';

import {
  getEvents,
  createEvent,
  deleteEvent,
} from '../api/event';
import { isJourFerie } from './calendarHelper';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Calendar',
  data() {
    return {
      me: {},
      users: [],
      events: [],
      month: '',
      year: '',
      formattedEmployeesAbsentees: [],
      tempos: ['2 semaines', '4 semaines', '26 semaines', '52 semaines'],
      typeOfAbsentees: [
        'Télétravail',
        'Réunion GIE',
        'CP',
        'CP - Matin',
        'CP - Après-midi',
        'RTT',
        'RTT - Matin',
        'RTT - Après-midi',
        'Temps partiel',
        'Sans solde',
        'Congé exceptionnel',
        'Maladie *',
        'Enfant malade *',
        'Enfant à charge',
        'Événements familiaux *',
      ],
      months: [
        'janvier',
        'février',
        'mars',
        'avril',
        'mai',
        'juin',
        'juillet',
        'août',
        'septembre',
        'octobre',
        'novembre',
        'décembre',
      ],
      formatAbsentee: {
        employeName: '',
        date: '',
        event_name: '',
        isRecurrent: false,
      },
      onlyOneEventToDelete: true,
      formatAbsenteeToDelete: {},
      isRecurrent: false,
      event_name: '',
      range: {
        start: new Date(),
        end: new Date(),
      },
      uniqueDate: '',
      listOfNext30Days: [],
      formattedNext30Days: [],
      daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      isDialogOpen: false,
      isCreateAbsenteeOpen: false,
      isModifyAbsenteeOpen: false,
      recurrentTempo: '',
      startDate: '',
      canBeModified: true,
    };
  },
  methods: {
    isJourFerie(date) {
      return isJourFerie(date);
    },
    async createEvent() {
      if (!this.formatAbsentee.event_name) {
        // eslint-disable-next-line no-alert
        alert('Veuillez choisir un type d\'absence');
        return;
      }
      if (this.formatAbsentee.isRecurrent && (this.formatAbsentee.recurrentTempo === '' || !this.formatAbsentee.recurrentTempo)) {
        // eslint-disable-next-line no-alert
        alert('Veuillez choisir une fréquence de dates');
        return;
      }
      try {
        this.formatAbsentee.start = this.range.start;
        this.formatAbsentee.end = this.range.end;
        if (this.formatAbsentee.recurrentTempo === '2 semaines') {
          // eslint-disable-next-line max-len
          this.formatAbsentee.end = new Date(this.formatAbsentee.start.getTime() + (7 * 24 * 60 * 60 * 1000));
        }
        if (this.formatAbsentee.recurrentTempo === '4 semaines') {
          // eslint-disable-next-line max-len
          this.formatAbsentee.end = new Date(this.formatAbsentee.start.getTime() + (21 * 24 * 60 * 60 * 1000));
        }
        if (this.formatAbsentee.recurrentTempo === '26 semaines') {
          // eslint-disable-next-line max-len
          this.formatAbsentee.end = new Date(this.formatAbsentee.start.getTime() + (175 * 24 * 60 * 60 * 1000));
        }
        if (this.formatAbsentee.recurrentTempo === '52 semaines') {
          // eslint-disable-next-line max-len
          this.formatAbsentee.end = new Date(this.formatAbsentee.start.getTime() + (357 * 24 * 60 * 60 * 1000));
        }
        await createEvent(this.formatAbsentee);
        this.isDialogOpen = false;
        this.events = (await getEvents()).events;
        this.setDateFromStartDate();
      } catch (error) {
        console.log(error);
      }
    },
    async deleteEvent() {
      try {
        if (this.canBeModified) {
          this.formatAbsenteeToDelete.onlyOneEventToDelete = this.onlyOneEventToDelete;
        } else {
          this.formatAbsenteeToDelete.onlyOneEventToDelete = false;
        }
        await deleteEvent(this.formatAbsenteeToDelete);
        this.isDialogOpen = false;
        this.events = (await getEvents()).events;
        this.formatEmployeesAbsentees();
      } catch (error) {
        console.log(error);
      }
    },
    async updateEvent() {
      await this.deleteEvent();
      await this.createEvent();
    },
    getAdminProfiles() {
      return this.users.filter((user) => user.role === 'admin');
    },
    isEmployee() {
      return this.me.role === 'employee';
    },
    setEventName(row, day) {
      if (this.isEmployee() && row.employeName !== `${this.me.first_name} ${this.me.last_name}`) {
        return '';
      }
      return row[`date_${day.getDate()}/${day.getMonth() + 1}/${day.getFullYear()}`].event_name;
    },
    getAllEventsFromEventId(eventId) {
      return this.events.filter((event) => event.event_id === eventId);
    },
    cellColor({ row, column }) {
      for (let i = 0; i < this.formattedNext30Days.length; i += 1) {
        if (column.type === true) {
          return 'cell-grey';
        }
        if (this.isEmployee() && row.employeName !== `${this.me.first_name} ${this.me.last_name}` && row[this.formattedNext30Days[i]].event_name !== undefined && column.property === this.formattedNext30Days[i]) {
          return 'cell-dark-grey';
        }
        if (row[this.formattedNext30Days[i]].event_name === 'TT' && column.property === this.formattedNext30Days[i]) {
          if (row[this.formattedNext30Days[i]].status === 'pending') {
            return 'cell-absent-pending';
          }
          return 'cell-absent';
        }
        if (row[this.formattedNext30Days[i]].event_name === 'CP' && column.property === this.formattedNext30Days[i]) {
          if (row[this.formattedNext30Days[i]].status === 'pending') {
            return 'cell-conges-pending';
          }
          return 'cell-conges';
        }
        if (row[this.formattedNext30Days[i]].event_name === 'CP am' && column.property === this.formattedNext30Days[i]) {
          if (row[this.formattedNext30Days[i]].status === 'pending') {
            return 'cell-conges-morning-pending';
          }
          return 'cell-conges-morning';
        }
        if (row[this.formattedNext30Days[i]].event_name === 'CP pm' && column.property === this.formattedNext30Days[i]) {
          if (row[this.formattedNext30Days[i]].status === 'pending') {
            return 'cell-conges-afternoon-pending';
          }
          return 'cell-conges-afternoon';
        }
        if (row[this.formattedNext30Days[i]].event_name === 'CP' && column.property === this.formattedNext30Days[i]) {
          if (row[this.formattedNext30Days[i]].status === 'pending') {
            return 'cell-conges-pending';
          }
          return 'cell-conges';
        }
        if (row[this.formattedNext30Days[i]].event_name === 'RTT' && column.property === this.formattedNext30Days[i]) {
          if (row[this.formattedNext30Days[i]].status === 'pending') {
            return 'cell-RTT-pending';
          }
          return 'cell-RTT';
        }
        if (row[this.formattedNext30Days[i]].event_name === 'RTT am' && column.property === this.formattedNext30Days[i]) {
          if (row[this.formattedNext30Days[i]].status === 'pending') {
            return 'cell-RTT-Matin-pending';
          }
          return 'cell-RTT-Matin';
        }
        if (row[this.formattedNext30Days[i]].event_name === 'RTT pm' && column.property === this.formattedNext30Days[i]) {
          if (row[this.formattedNext30Days[i]].status === 'pending') {
            return 'cell-RTT-Après-midi-pending';
          }
          return 'cell-RTT-Après-midi';
        }
        if (row[this.formattedNext30Days[i]].event_name === 'TP' && column.property === this.formattedNext30Days[i]) {
          if (row[this.formattedNext30Days[i]].status === 'pending') {
            return 'cell-TP-pending';
          }
          return 'cell-TP';
        }
        if (row[this.formattedNext30Days[i]].event_name === 'RE' && column.property === this.formattedNext30Days[i]) {
          if (row[this.formattedNext30Days[i]].status === 'pending') {
            return 'cell-GIE-pending';
          }
          return 'cell-GIE';
        }
        if (row[this.formattedNext30Days[i]].event_name === 'SS' && column.property === this.formattedNext30Days[i]) {
          if (row[this.formattedNext30Days[i]].status === 'pending') {
            return 'cell-SS-pending';
          }
          return 'cell-SS';
        }
        if (row[this.formattedNext30Days[i]].event_name === 'CE' && column.property === this.formattedNext30Days[i]) {
          if (row[this.formattedNext30Days[i]].status === 'pending') {
            return 'cell-CE-pending';
          }
          return 'cell-CE';
        }
        if (row[this.formattedNext30Days[i]].event_name === 'MA' && column.property === this.formattedNext30Days[i]) {
          if (row[this.formattedNext30Days[i]].status === 'pending') {
            return 'cell-MA-pending';
          }
          return 'cell-MA';
        }
        if (row[this.formattedNext30Days[i]].event_name === 'EM' && column.property === this.formattedNext30Days[i]) {
          if (row[this.formattedNext30Days[i]].status === 'pending') {
            return 'cell-EM-pending';
          }
          return 'cell-EM';
        }
        if (row[this.formattedNext30Days[i]].event_name === 'EC' && column.property === this.formattedNext30Days[i]) {
          if (row[this.formattedNext30Days[i]].status === 'pending') {
            return 'cell-EC-pending';
          }
          return 'cell-EC';
        }
        if (row[this.formattedNext30Days[i]].event_name === 'EF' && column.property === this.formattedNext30Days[i]) {
          if (row[this.formattedNext30Days[i]].status === 'pending') {
            return 'cell-EF-pending';
          }
          return 'cell-EF';
        }
      }
      return '';
    },
    getAbsenteeColor(absentee) {
      if (absentee === 'Télétravail') {
        return 'cell-absent';
      }
      if (absentee === 'CP') {
        return 'cell-conges';
      }
      if (absentee === 'CP - Matin') {
        return 'cell-conges-morning';
      }
      if (absentee === 'CP - Après-midi') {
        return 'cell-conges-afternoon';
      }
      if (absentee === 'RTT') {
        return 'cell-RTT';
      }
      if (absentee === 'RTT - Matin') {
        return 'cell-RTT-Matin';
      }
      if (absentee === 'RTT - Après-midi') {
        return 'cell-RTT-Après-midi';
      }
      if (absentee === 'Temps partiel') {
        return 'cell-TP';
      }
      if (absentee === 'Réunion GIE') {
        return 'cell-GIE';
      }
      if (absentee === 'Sans solde') {
        return 'cell-SS';
      }
      if (absentee === 'Congé exceptionnel') {
        return 'cell-CE';
      }
      if (absentee === 'Maladie *') {
        return 'cell-MA';
      }
      if (absentee === 'Enfant malade *') {
        return 'cell-EM';
      }
      if (absentee === 'Enfant à charge') {
        return 'cell-EC';
      }
      if (absentee === 'Événements familiaux *') {
        return 'cell-EF';
      }
      return '';
    },
    formatEventName(eventName) {
      if (eventName === 'Télétravail') {
        return 'TT';
      }
      if (eventName === 'Temps partiel') {
        return 'TP';
      }
      if (eventName === 'Réunion GIE') {
        return 'RE';
      }
      if (eventName === 'Sans solde') {
        return 'SS';
      }
      if (eventName === 'Congé exceptionnel') {
        return 'CE';
      }
      if (eventName === 'Maladie *') {
        return 'MA';
      }
      if (eventName === 'Enfant malade *') {
        return 'EM';
      }
      if (eventName === 'Enfant à charge') {
        return 'EC';
      }
      if (eventName === 'Événements familiaux *') {
        return 'EF';
      }
      if (eventName === 'CP - Matin') {
        return 'CP am';
      }
      if (eventName === 'CP - Après-midi') {
        return 'CP pm';
      }
      if (eventName === 'RTT - Matin') {
        return 'RTT am';
      }
      if (eventName === 'RTT - Après-midi') {
        return 'RTT pm';
      }
      return eventName;
    },
    handleCellClick(row, column) {
      const { employeName } = row;
      if (this.isEmployee() && row.employeName !== `${this.me.first_name} ${this.me.last_name}`) {
        return;
      }
      const absenteeType = row[column.property];
      if (absenteeType && (absenteeType.status === 'pending')) {
        this.canBeModified = false;
        this.onlyOneEventToDelete = false;
      } else {
        this.canBeModified = true;
        this.onlyOneEventToDelete = true;
      }
      const date = column.property;
      // On réécrit la date pour la passer en format Date
      let dateFormatted = date.replace('date_', '');
      dateFormatted = new Date(dateFormatted.split('/').reverse().join('-'));
      this.range.start = dateFormatted;
      this.range.end = dateFormatted;
      this.uniqueDate = dateFormatted;
      let isRecurrent = false;
      let isPending = false;
      if (absenteeType.event && absenteeType.event.event_type_id === 1) {
        isRecurrent = true;
      }
      if (absenteeType.event && absenteeType.event.status === 'pending') {
        isPending = true;
      }
      if (absenteeType.event) {
        const events = this.getAllEventsFromEventId(absenteeType.event_id);
        this.range.start = new Date(events[0].start);
        this.range.end = new Date(events[events.length - 1].end);
      }
      if (!isRecurrent) {
        this.onlyOneEventToDelete = false;
      }
      this.formatAbsentee = {
        userId: this.findUserIdFromIsName(employeName),
        employeName,
        date,
        start: this.range.start,
        end: this.range.end,
        dateFormatted,
        type: absenteeType,
        status: 'pending',
        isPending,
        eventId: absenteeType.event_id,
        isRecurrent,
        email: row.email,
        onlyOneEventToDelete: this.onlyOneEventToDelete,
        admins: this.getAdminProfiles(),
      };
      if (absenteeType === '') {
        this.isModifyAbsenteeOpen = false;
        this.isCreateAbsenteeOpen = true;
      } else {
        this.formatAbsentee.event_name = this.formatEventName(absenteeType.event_name);
        this.isCreateAbsenteeOpen = false;
        this.isModifyAbsenteeOpen = true;
      }
      this.isDialogOpen = true;
      this.formatAbsenteeToDelete = { ...this.formatAbsentee };
    },
    getListOfNext14DaysFromDate() {
      const next14Days = [];
      for (let i = 0; i < 14; i += 1) {
        const date = new Date(this.startDate);
        date.setDate(date.getDate() + i);
        next14Days.push(date);
      }
      return next14Days;
    },
    formatNext30Days() {
      const next30Days = this.getListOfNext14DaysFromDate();
      const formattedNext30Days = [];
      next30Days.forEach((day) => {
        formattedNext30Days.push(`date_${day.getDate()}/${day.getMonth() + 1}/${day.getFullYear()}`);
      });
      this.formattedNext30Days = formattedNext30Days;
    },
    getListOfPrevious30Days() {
      const today = new Date();
      const previousDays = [];
      for (let i = 0; i < 30; i += 1) {
        const date = new Date(today);
        date.setDate(date.getDate() - i);
        previousDays.push(date);
      }
      return previousDays;
    },
    formatEmployeesAbsentees() {
      const formattedEmployeesAbsentees = [];
      this.users.forEach((user) => {
        const employee = {
          employeName: `${user.first_name} ${user.last_name}`,
          employeFirstName: `${user.first_name}`,
          employeLastName: `${user.last_name}`,
        };
        employee.email = user.email;
        this.formattedNext30Days.forEach((day) => {
          employee[day] = '';
        });
        this.events.forEach((event) => {
          if (event.event_status === 0 || event.event_status === '0') return;
          if (event.user_id === user.id) {
            const start = new Date(event.start);
            const formattedDate = `date_${start.getDate()}/${start.getMonth() + 1}/${start.getFullYear()}`;
            if (this.formattedNext30Days.includes(formattedDate)) {
              employee[formattedDate] = {
                event_name: this.formatEventName(event.event_name),
                status: event.event_status,
                event_id: event.event_id,
                event,
              };
            }
          }
        });
        formattedEmployeesAbsentees.push(employee);
      });
      // Mettre les profiles par ordre alphabétique des noms
      formattedEmployeesAbsentees.sort((a, b) => {
        if (a.employeLastName < b.employeLastName) {
          return -1;
        }
        if (a.employeLastName > b.employeLastName) {
          return 1;
        }
        return 0;
      });
      // mettre mon profile en premier
      if (this.me && this.me.first_name && this.me.last_name) {
        const myProfile = formattedEmployeesAbsentees.find(
          (employee) => employee.employeName === `${this.me.first_name} ${this.me.last_name}`,
        );
        const index = formattedEmployeesAbsentees.indexOf(myProfile);
        formattedEmployeesAbsentees.splice(index, 1);
        formattedEmployeesAbsentees.unshift(myProfile);
      }

      this.formattedEmployeesAbsentees = formattedEmployeesAbsentees;
    },
    findUserIdFromIsName(employeName) {
      const user = this.users.find((us) => `${us.first_name} ${us.last_name}` === employeName);
      return user.id;
    },
    setDateFromStartDate() {
      if (this.formatAbsentee.start) {
        this.startDate = new Date(this.formatAbsentee.start);
        this.startDate.setDate(this.startDate.getDate() - this.startDate.getDay() + 1);
        this.listOfNext30Days = this.getListOfNext14DaysFromDate();
      }
      this.formatNext30Days();
      this.formatEmployeesAbsentees();
    },
    setDateFirstDayOfWeek() {
      this.startDate = new Date();
      this.startDate.setDate(this.startDate.getDate() - this.startDate.getDay() + 1);
    },
    setToday() {
      this.setDateFirstDayOfWeek();
      this.listOfNext30Days = this.getListOfNext14DaysFromDate();
      this.formatNext30Days();
      this.formatEmployeesAbsentees();
    },
    setNextWeek() {
      this.startDate.setDate(this.startDate.getDate() + 7);
      this.startDate.setDate(this.startDate.getDate() - this.startDate.getDay() + 1);
      this.listOfNext30Days = this.getListOfNext14DaysFromDate();
      this.formatNext30Days();
      this.formatEmployeesAbsentees();
    },
    setPreviousWeek() {
      this.startDate.setDate(this.startDate.getDate() - 7);
      this.startDate.setDate(this.startDate.getDate() - this.startDate.getDay() + 1);
      this.listOfNext30Days = this.getListOfNext14DaysFromDate();
      this.formatNext30Days();
      this.formatEmployeesAbsentees();
    },
    setNextMonth() {
      // On rajoute 7 jours pour ne pas être bloqué si on est à cheval sur 2 mois
      this.startDate.setDate(this.startDate.getDate() + 7);
      this.startDate.setMonth(this.startDate.getMonth() + 1);
      this.startDate.setDate(1);
      this.startDate.setDate(this.startDate.getDate() - this.startDate.getDay() + 1);
      this.listOfNext30Days = this.getListOfNext14DaysFromDate();
      this.formatNext30Days();
      this.formatEmployeesAbsentees();
    },
    setPreviousMonth() {
      this.startDate.setMonth(this.startDate.getMonth() - 1);
      this.startDate.setDate(1);
      this.startDate.setDate(this.startDate.getDate() - this.startDate.getDay() + 1);
      this.listOfNext30Days = this.getListOfNext14DaysFromDate();
      this.formatNext30Days();
      this.formatEmployeesAbsentees();
    },
    getMonth() {
      // Get month in French from startDate
      const date = new Date(this.startDate);
      const month = date.getMonth();
      const monthNameStart = this.months[month];
      date.setDate((date.getDate() + 14));
      const monthNameEnd = this.months[date.getMonth()];
      if (monthNameStart === monthNameEnd) {
        return monthNameStart;
      }
      return `${monthNameStart} - ${monthNameEnd}`;
    },
    getYear() {
      // Get year from startDate
      const year = new Date(this.startDate).getFullYear();
      return year;
    },
  },
  async mounted() {
    this.setDateFirstDayOfWeek();
    this.listOfNext30Days = this.getListOfNext14DaysFromDate();
    this.formatNext30Days();
    this.users = (await getUsers()).users;
    this.me = this.users.find((user) => user.email === this.$store.state.user.email);
    this.$store.commit('setMe', this.me);
    this.events = (await getEvents()).events;
    this.formatEmployeesAbsentees();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import './calendar.css';
</style>
